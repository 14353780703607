import {useState} from 'react';

import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
  Button,
  Box
} from '@chakra-ui/react'

import {
  ArrowForwardIcon,
  EmailIcon
} from '@chakra-ui/icons'

import useNetworkStatus from '../hooks/useNetworkStatus'


function NfcPage() {
  
  const [nfcText, setnfcText] = useState<any>('');

  const abortController = new AbortController();
  abortController.signal.onabort = event => {
    // All NFC operations have been aborted.
    
  };

  const nfc = () => {
    if ('NDEFReader' in window) {
      const ndef = new NDEFReader();

      var textTemp = '';

      ndef.scan({signal: abortController.signal}).then(() => {
        console.log("Scan started successfully.");
        textTemp += ';Scan started successfully.';
        setnfcText(textTemp);
        ndef.onreading = event => {

          const decoder = new TextDecoder();
          
          const message = event.message;
          for (const record of message.records) {
            console.log("Record type:  " + record.recordType);
            console.log("MIME type:    " + record.mediaType);
            console.log("Record id:    " + record.id);
  
            //textTemp += ';' + record.recordType;
            textTemp += ';' + record.recordType + ': ' + decoder.decode(record.data);
            setnfcText(nfcText + ';' + textTemp);
            switch (record.recordType) {
              case "text": 
                if (record.data !== undefined) {
                  //textTemp += ';' + record.data;
                }
                break;
              case "url":
                // TODO: Read URL record with record data.
                break;
              default:
                // TODO: Handle other records with record data.
            }
            

            
          }

          abortController.abort();
        };
      }).catch((error: any) => {
        console.log(`Error! Scan failed to start: ${error}.`);
        textTemp += ';Error! Scan failed to start';
        setnfcText(textTemp);
      });

      



    } else {
      console.log('a:'+nfcText);
      setnfcText(nfcText + ';Brak NFC');
    }

    
    
  }

  

  return (
    <>
    <Stack>

    <Button onClick={() => {
      nfc();
      console.log(nfcText);
      //setnfcText(nfcText + ';Click');
    }}>Skanuj NFC</Button>
    <Button onClick={() => {
      abortController.abort();
      console.log('Przerwano skanowanie');
      setnfcText(nfcText + ';Przerwano skanowanie');
    }}>Przerwij skanowanie NFC New</Button>
    <Box >{nfcText}</Box>
</Stack>
</>
  )
}

export default NfcPage;
