import { useNetworkStatus } from '@rennalabs/hooks';

function NetworkStatus() {
  const { isOnline, offlineAt } = useNetworkStatus();
  console.log('NetworkStatus: '+isOnline);

  return (
    <div style={{ background: isOnline ? 'green' : 'red' }}>
      {`App went offline at`}
    </div>
  );
};

export default NetworkStatus;