import {useState} from 'react';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
  Button,
} from '@chakra-ui/react'

import {
  ArrowForwardIcon,
  EmailIcon
} from '@chakra-ui/icons'

import useNetworkStatus from './../hooks/useNetworkStatus'


function Form() {

  const [value, setValue] = useState(15);
  const handleChange = (valueString: string, valueNumber: number) => setValue(valueNumber);

  return (
    <>
    
  <Stack direction='row' spacing={4} marginTop={5}>
  <NumberInput size='lg' defaultValue={15} min={10} max={20} value={value} onChange={handleChange}>
    <NumberInputField />
    <NumberInputStepper>
      <NumberIncrementStepper />
      <NumberDecrementStepper />
    </NumberInputStepper>
  </NumberInput>
  <Button 
    rightIcon={<ArrowForwardIcon />} 
    colorScheme='teal' 
    variant='outline'
    onClick={() => {
      //localStorage.setItem('input', '10')
      alert(value);
    }}
  >
    Zapisz
  </Button>
  <Button 
    rightIcon={<ArrowForwardIcon />} 
    colorScheme='teal' 
    variant='outline'
    onClick={() => {
      
    }}
  >
    Zapisz
  </Button>
</Stack>
</>
  )
}

export default Form;
