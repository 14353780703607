import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LayoutNavbar from './pages/LayoutNavbar';
import Layout from './pages/Layout';
import NoPage from './pages/NoPage';
import Home from './pages/Home';
import Form from './pages/Form';
import NfcPage from './pages/NfcPage';

import logo from './logo.svg';
import './App.css';


function App() {

  //const { isOnline } = useNetworkStatus();


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutNavbar />}>
          <Route index element={<Home />} />
          <Route path="form" element={<Form />} />
          <Route path="nfc" element={<NfcPage />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
