'use client'

import { Outlet, NavLink } from "react-router-dom"
import {
  ChakraProvider,
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Switch
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import useNetworkStatus from './../hooks/useNetworkStatus'
import NetworkStatus from './../components/NetworkStatus'
import metadata from './../metadata.json'

interface Props {
  title: string,
  href: string
}

const Links = [
  {
    title: 'Start',
    href: '/'
  },
  {
    title: 'Form',
    href: '/form'
  },
  {
    title: 'NFC Scan',
    href: '/nfc'
  }
];

const NavLink1 = (props: Props) => {
  //const { children } = props

  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      href={props.href}>
      {props.title}
    </Box>
  )
}

export default function LayoutNavbar(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  

  return (
    <>
      <ChakraProvider>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Box>Logo</Box>
            <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
              {Links.map((link) => (
                //<NavLink title={link.title} href={link.href} />
                <NavLink to={link.href}>{link.title} </NavLink>
              ))}
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
            <NetworkStatus />
            <Switch id='email-alerts1' margin={4} isChecked={useNetworkStatus().isOnline} />
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                <Avatar
                  size={'sm'}
                  src={
                    'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                />
              </MenuButton>
              <MenuList>
                <MenuItem>Link 1</MenuItem>
                <MenuItem>Link 2</MenuItem>
                <MenuDivider />
                <MenuItem>Link 3</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                //<NavLink title={link.title} href={link.href} />
                <NavLink to={link.href}>{link.title} </NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Box p={4}><Outlet /></Box>
      <div className="sf-footer-version">
        {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
      </div>
      </ChakraProvider>
    </>
  )
}